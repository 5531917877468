import { useDispatch, useSelector } from 'src/store';
import CheckboxList from './CheckboxList';
import CheckboxListItem from './CheckboxListItem';
import { useAnalyticsEventTracker } from '@/gaEvents';
import { TelemetryKey, toggleTelemetry } from '../../../slices/devicesAnalysisSlice';
import { MetricField } from '@/__generated__/graphql';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

const SidebarAdvancedCheckboxList = () => {
  const dispatch = useDispatch();
  const { _ } = useLingui();
  const { telemetry } = useSelector((state) => state.devices_analysis_store);
  const { checkBoxEvent } = useAnalyticsEventTracker('Station Analysis');

  return (
    <CheckboxList>
      {telemetry.availableFields.includes(MetricField.Flow) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.FlowRaw]}
          onClick={() => {
            checkBoxEvent({ label: 'flow-raw', value: !telemetry[TelemetryKey.FlowRaw] });
            dispatch(toggleTelemetry(TelemetryKey.FlowRaw));
          }}
          label={_(msg`Caudal bruto`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.Level) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.LevelRaw]}
          onClick={() => {
            checkBoxEvent({ label: 'level-raw', value: !telemetry[TelemetryKey.LevelRaw] });
            dispatch(toggleTelemetry(TelemetryKey.LevelRaw));
          }}
          label={_(msg`Nivel bruto`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.AngleNonCalibrated) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.AngleNonCalibrated]}
          onClick={() => {
            checkBoxEvent({
              label: 'angle-non-calibrated',
              value: !telemetry[TelemetryKey.AngleNonCalibrated],
            });
            dispatch(toggleTelemetry(TelemetryKey.AngleNonCalibrated));
          }}
          label={_(msg`Movimiento radial sin calibrar`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.Velocity) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.VelocityRaw]}
          onClick={() => {
            checkBoxEvent({ label: 'velocity-raw', value: !telemetry[TelemetryKey.VelocityRaw] });
            dispatch(toggleTelemetry(TelemetryKey.VelocityRaw));
          }}
          label={_(msg`Velocidad superficial bruta`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.Temperature) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.TemperatureRaw]}
          onClick={() => {
            checkBoxEvent({
              label: 'temperature-raw',
              value: !telemetry[TelemetryKey.TemperatureRaw],
            });
            dispatch(toggleTelemetry(TelemetryKey.TemperatureRaw));
          }}
          label={_(msg`Temperatura bruta`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.Conductivity) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.ConductivityRaw]}
          onClick={() => {
            checkBoxEvent({
              label: 'conductivity-raw',
              value: !telemetry[TelemetryKey.ConductivityRaw],
            });
            dispatch(toggleTelemetry(TelemetryKey.ConductivityRaw));
          }}
          label={_(msg`Conductividad bruta`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.Ph) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.PhRaw]}
          onClick={() => {
            checkBoxEvent({ label: 'ph-raw', value: !telemetry[TelemetryKey.PhRaw] });
            dispatch(toggleTelemetry(TelemetryKey.PhRaw));
          }}
          label={_(msg`pH bruto`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.Orp) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.OrpRaw]}
          onClick={() => {
            checkBoxEvent({ label: 'orp-raw', value: !telemetry[TelemetryKey.OrpRaw] });
            dispatch(toggleTelemetry(TelemetryKey.OrpRaw));
          }}
          label={_(msg`ORP bruto`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.DissolvedOxygen) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.DissolvedOxygenRaw]}
          onClick={() => {
            checkBoxEvent({
              label: 'dissolved-oxygen-mg-l-raw',
              value: !telemetry[TelemetryKey.DissolvedOxygenRaw],
            });
            dispatch(toggleTelemetry(TelemetryKey.DissolvedOxygenRaw));
          }}
          label={_(msg`Oxígeno disuelto bruto`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.DissolvedOxygenSaturation) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.DissolvedOxygenSaturationRaw]}
          onClick={() => {
            checkBoxEvent({
              label: 'dissolved-oxygen-saturation-raw',
              value: !telemetry[TelemetryKey.DissolvedOxygenSaturationRaw],
            });
            dispatch(toggleTelemetry(TelemetryKey.DissolvedOxygenSaturationRaw));
          }}
          label={_(msg`Saturación de oxígeno disuelto bruto`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.TurbidityNtu) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.TurbidityNtuRaw]}
          onClick={() => {
            checkBoxEvent({
              label: 'turbidity-ntu-raw',
              value: !telemetry[TelemetryKey.TurbidityNtuRaw],
            });
            dispatch(toggleTelemetry(TelemetryKey.TurbidityNtuRaw));
          }}
          label={_(msg`Turbidez NTU bruta`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.TurbidityFnu) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.TurbidityFnuRaw]}
          onClick={() => {
            checkBoxEvent({
              label: 'turbidity-fnu-raw',
              value: !telemetry[TelemetryKey.TurbidityFnuRaw],
            });
            dispatch(toggleTelemetry(TelemetryKey.TurbidityFnuRaw));
          }}
          label={_(msg`Turbidez FNU bruta`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.SnowLevel) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.SnowLevelRaw]}
          onClick={() => {
            checkBoxEvent({
              label: 'snow-level-raw',
              value: !telemetry[TelemetryKey.SnowLevelRaw],
            });
            dispatch(toggleTelemetry(TelemetryKey.SnowLevelRaw));
          }}
          label={_(msg`Nivel de nieve bruto`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.SnowLevelNonCalibrated) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.SnowLevelNonCalibrated]}
          onClick={() => {
            checkBoxEvent({
              label: 'snow-water-level-non-calibrated',
              value: !telemetry[TelemetryKey.SnowLevelNonCalibrated],
            });
            dispatch(toggleTelemetry(TelemetryKey.SnowLevelNonCalibrated));
          }}
          label={_(msg`Distancia sensor`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.SnowTemperature) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.SnowTemperatureRaw]}
          onClick={() => {
            checkBoxEvent({
              label: 'snow-temperature-raw',
              value: !telemetry[TelemetryKey.SnowTemperatureRaw],
            });
            dispatch(toggleTelemetry(TelemetryKey.SnowTemperatureRaw));
          }}
          label={_(msg`Temperatura bruta`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.SnowRadiation) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.SnowRadiationRaw]}
          onClick={() => {
            checkBoxEvent({
              label: 'snow-radiation-raw',
              value: !telemetry[TelemetryKey.SnowRadiationRaw],
            });
            dispatch(toggleTelemetry(TelemetryKey.SnowRadiationRaw));
          }}
          label={_(msg`Radiación bruta`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.SnowWaterEquivalent) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.SnowWaterEquivalentRaw]}
          onClick={() => {
            checkBoxEvent({
              label: 'snow-water-equivalent-raw',
              value: !telemetry[TelemetryKey.SnowWaterEquivalentRaw],
            });
            dispatch(toggleTelemetry(TelemetryKey.SnowWaterEquivalentRaw));
          }}
          label={_(msg`Equivalente de agua de nieve bruto`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.SnowWaterEquivalentNonCalibrated) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.SweNonCalibrated]}
          onClick={() => {
            checkBoxEvent({
              label: 'snow-water-equivalent-non-calibrated',
              value: !telemetry[TelemetryKey.SweNonCalibrated],
            });
            dispatch(toggleTelemetry(TelemetryKey.SweNonCalibrated));
          }}
          label={_(msg`Equivalente de agua de nieve sin calibrar`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.RatingCurvesLimits) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.RatingCurvesLimits]}
          onClick={() => {
            checkBoxEvent({
              label: 'rating-limits',
              value: !telemetry[TelemetryKey.RatingCurvesLimits],
            });
            dispatch(
              toggleTelemetry({
                [TelemetryKey.RatingCurvesLimits]: !telemetry[TelemetryKey.RatingCurvesLimits],
                ...(!telemetry[TelemetryKey.RatingCurvesLimits] && {
                  [TelemetryKey.Level]: true,
                }),
              }),
            );
          }}
          label={_(msg`Límites de curvas de aforo`)}
        />
      )}
    </CheckboxList>
  );
};

export default SidebarAdvancedCheckboxList;
